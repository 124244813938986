<template>
  <v-container v-if="this.discount" fluid>
    <v-card elevation="0">
      <v-toolbar
          color="white"
          flat
      >
        <v-btn
            icon
            light
            link
            to="/discounts"
        >
          <v-icon color="grey darken-2">
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title class="grey--text text--darken-4">
          Kortingscode
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="error"
               class="delete mr-2" @click="remove" v-if="discount.ID">Verwijderen</v-btn>
        <v-btn color="primary" @click="save">
          Bewaren
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col md="6" sm="12">
            <v-text-field
                v-model="discount.Name"
                label="Omschrijving kortingscode"
                outlined
                required
            />
          </v-col>
          <v-col md="6" sm="12">
            <v-text-field
                v-model="discount.Code"
                label="Kortingscode"
                outlined
                required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" sm="12">
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date1"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    hide-details
                    label="Geldig van"
                    outlined
                    readonly
                    v-on:click:append="menu1=true"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="discount.From"
                  min="1950-01-01"
                  @change="saveFrom"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="6" sm="12">
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date2"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    hide-details
                    label="Geldig tot"
                    outlined
                    readonly
                    v-on:click:append="menu2=true"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="discount.Till"
                  min="1950-01-01"
                  @change="saveTill"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="3" sm="6">
            <v-select v-model="discount.Type" :items="typeItems" item-text="name" item-value="id"
                      label="Type kortingscode" outlined></v-select>
          </v-col>
          <v-col md="3" sm="6">
            <v-text-field
                v-model="discount.Discount"
                label="Waarde korting"
                outlined
                required
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Discount} from "@/models/discount";
import {DiscountService} from "@/services/discount-service";

export default {
  data() {
    return {
      menu1: false,
      menu2: false,
      discount: new Discount(),
    }
  },
  computed: {
    date1() {
      if(!this.discount.From)
        return

      let t = new Date(this.discount.From);
      return t.toLocaleDateString("nl-NL", {
        weekday: 'short',
        timeZone: 'UTC'
      }) + ", " + t.getDate() + " " + t.toLocaleString("nl-NL", {
        month: 'short',
        timeZone: 'UTC'
      }) + " " + t.getFullYear();
    },
    date2() {
      if(!this.discount.Till)
        return

      let t = new Date(this.discount.Till);
      return t.toLocaleDateString("nl-NL", {
        weekday: 'short',
        timeZone: 'UTC'
      }) + ", " + t.getDate() + " " + t.toLocaleString("nl-NL", {
        month: 'short',
        timeZone: 'UTC'
      }) + " " + t.getFullYear();
    },
    typeItems() {
      return this.discount.getDiscountTypes();
    },
  },
  methods: {
    async save(){
      if(this.discount.ID){
        await DiscountService.update(this.discount);
      }else{
        await DiscountService.insert(this.discount);
      }
      this.$router.push('/discounts');
    },
    async remove(){
      await DiscountService.remove(this.discount.ID)
      this.$router.push('/discounts');
    },
    saveFrom($event) {
      this.discount.From = new Date($event);
      this.menu1 = false;
    },
    saveTill($event) {
      this.discount.Till = new Date($event);
      this.menu2 = false;
    }
  },
  async created() {
    if (parseInt(this.$route.params.id)>0) {
      this.discount = await DiscountService.single(this.$route.params.id)
    }else{
      this.discount = new Discount({From:null,Till:null})
    }
  }
}
</script>